<template>

    <div class="vue-box">

        <div class="header">
            <el-row>
                <el-col :span="4">
                    <div class="title">{{title}}</div>
                </el-col>

                <el-col :span="16">
                     <button-project enumName="ProjectStateEnum"   @fatherMethod="ok"></button-project>
                </el-col>

            </el-row>
        </div>

        <div class="c-panel">
            <el-row v-for="g,index in group" justify="space-between" :key="index">
                <el-col :span="6" v-for="item in g" :key="item.id">
                    <el-card class="box-card">
                        <div @click="goto(item)">
                            <el-row>
                                <el-col style="height: 50px;font-size: 21px;font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;color: #2D2D2D;line-height: 29px;">{{item.name}}</el-col>
                                <el-col style="height: 30px;">地点： {{item.address}}</el-col>
                                <el-col style="height: 30px;">项目交付时间： {{item.finishDate}}</el-col>
                                 <el-col style="height: 30px">合同编号 ：{{item.contactCode}}</el-col>
                            </el-row>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import ButtonProject from '../../sa-resources/com-view/button-project.vue';
import inputEnum from '../../sa-resources/com-view/input-enum.vue';

export default {
    components: { inputEnum, ButtonProject },
    data() {
        return {
            group: [],
            title: "项目实施",
            state: '',
            counts: [],
            p: {
                pageSize: 100,
                page: 1,
                state: 1
            }

        }
    },
    methods: {
        ok(state) {
            this.p.state = state;
            this.f5();
            console.log("查询", this.state)
        },
        f5() {
            this.group = [];
            this.sa.get("/purchase/groupByProjectId").then(res => {
                this.counts = res.data;
            })

            this.sa.put("/project/listPage", this.p).then(res => {
                let item = [];
                res.data.content.forEach((element, index) => {
                    item.push(element);
                    if ((index + 1) % 4 == 0) {
                        this.group.push(item);
                        item = [];
                    }
                });
                if (item.length > 0) {
                    this.group.push(item)
                }
                console.log(this.group);
            })

        },
        goto(row) {
            console.log("跳转了", row);
            this.sa_admin.navigateTo("operation-index", row);
        }
    },
    created() {
        this.title = this.sa_admin.nativeTab.name;
        this.f5();


    }
}
</script>

<style scoped>
.box-card {
    margin: 10px;
}
.title {
    width: 84px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2d2d2d;
    line-height: 29px;
    padding-left: 20px;
    border-left: 3px solid #4983f4;
}

.header {
    margin: 20px;
}
.c-panel {
    background-color: #eee;
}
</style>